import { appEnvironment } from './app.environment';

export const environment = {
  "ng.buildModeProduction": true, // don't change, true only for this file and false in prod.environment.ts
  "app.version": String(appEnvironment.version),
  "app.google.maps.key": String(appEnvironment.googleMapKey || '').trim(),
  "env.isProduction": true,
  "env.name": "prod",
  "gateway.browser.host": "api.modaapp.co",
  "gateway.upload.host": "fs.modaapp.co",
  "sentry.browser.dsn": String(appEnvironment.sentryBrowser || '').trim(),
  "pusher.auth.key": String(appEnvironment.pusherAuthKey || '').trim(),
  "pusher.super.channel": String(appEnvironment.pusherSuperChannel || '').trim(),
  "keycloak.url": "https://auth.modaapp.co",
  "keycloak.realm": "moda",
  "keycloak.clientId": "partner-admin-ui",
  "imageStore": "https://static-production.modaapp.co/cdn-cgi/image",
};
